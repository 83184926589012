import React, { useState } from "react";
import { toast } from "react-toastify";

import { apiPost } from "../../api/apiService";
import Loader from "../../components/loader/Loader";

interface VerifyEmailProps {
  email: string;
  onOtpSent: (token: string) => void; // Callback when OTP is sent
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ email, onOtpSent }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSendOtp = async () => {
    setIsLoading(true);
    try {
      const apiPayload = {
        email: email,
      };
      const response = await apiPost("/auth/send-verification-otp", apiPayload);
      if (response.status === 200) {
        const token = response.data?.data?.token;
        toast.success("OTP sent successfully!");
        onOtpSent(token); // Trigger the callback to switch to OTP input
      }
    } catch (error) {
      console.error("Error sending OTP, Please try again.");
      toast.error("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold">Verify Your Email</h2>
      <p className="text-sm text-gray-600 mt-4">
        We sent an email to: <strong>{email}</strong>
      </p>
      <p className="text-sm text-gray-600 mt-2">
        If this email is correct, click the button below to send an OTP and
        verify your account.
      </p>
      <button
        className="btn btn-primary w-full mt-4"
        onClick={handleSendOtp}
        disabled={isLoading}
      >
        {isLoading ? "Sending OTP..." : "Send OTP"}
      </button>
      {isLoading && <Loader />}
    </div>
  );
};

export default VerifyEmail;
