import React from "react";
import { CrossIcon, DeleteIcon, RenameIcon } from "../../assets";

interface ChatOptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  chatId: string | null;
}

const ChatOptionsModal: React.FC<ChatOptionsModalProps> = ({
  isOpen,
  onClose,
  chatId,
}) => {
  if (!isOpen || chatId === null) return null;

  const handleRename = () => {
    // Handle rename logic here
    console.log("Renaming chat with ID:", chatId);
    onClose();
  };

  const handleDelete = () => {
    // Handle delete logic here
    console.log("Deleting chat with ID:", chatId);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-700 rounded-lg shadow-lg w-80 p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Options</h2>
          <button onClick={onClose} aria-label="Close modal">
            <CrossIcon />
          </button>
        </div>
        <ul className="space-y-3">
          <li
            className="cursor-pointer p-2 rounded hover:bg-[#30364296] hover:text-white transition duration-200 flex items-center gap-1"
            onClick={handleRename}
          >
            <RenameIcon />
            <span className="text-lg">Rename</span>
          </li>
          <li
            className="cursor-pointer p-2 rounded hover:bg-[#30364296] transition duration-200 flex items-center text-red-500 gap-1"
            onClick={handleDelete}
          >
            <DeleteIcon />
            <span className="text-lg">Delete</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChatOptionsModal;
