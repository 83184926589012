import React, { useEffect, useState } from "react";
import { apiPost } from "../../api/apiService";
import { toast } from "react-toastify";

interface OtpInputProps {
  otp: string[];
  onOtpChange: (otp: string[]) => void;
  email?: string;
  handleOtpVerification: (e: React.FormEvent) => Promise<void>;
  setIsLoading: (value: boolean) => void;
  handleFormStateData: (toekn: string) => void;
}

const OtpInput: React.FC<OtpInputProps> = ({
  otp,
  onOtpChange,
  email,
  handleOtpVerification,
  setIsLoading,
  handleFormStateData,
}) => {
  const [timer, setTimer] = useState<number>(120); // 2 minutes in seconds
  const [isResendDisabled, setIsResendDisabled] = useState<boolean>(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState<boolean>(false);

  const handleOtpChange = (value: string, index: number) => {
    const digit = value.slice(0, 1); // Ensure only one digit is entered
    const newOtp = [...otp];
    newOtp[index] = digit;
    onOtpChange(newOtp);

    // Move to the next input if filled
    if (digit.length === 1 && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleOtpKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-${index - 1}`)?.focus();
    }
  };

  const handleOtpPaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const pasteData = e.clipboardData.getData("text");
    const pasteOtp = pasteData.split("").slice(0, 6);
    const newOtp = [...otp];

    pasteOtp.forEach((digit, i) => {
      if (index + i < newOtp.length) {
        newOtp[index + i] = digit;
      }
    });

    onOtpChange(newOtp);

    // Focus the last filled input
    const lastIndex = Math.min(index + pasteOtp.length - 1, otp.length - 1);
    const lastInput = document.getElementById(`otp-${lastIndex}`);
    if (lastInput) lastInput.focus();

    e.preventDefault();
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    try {
      const apiPayload = {
        email: email,
      };
      const response = await apiPost("/auth/send-verification-otp", apiPayload);
      if (response.status === 200) {
        const data = response.data?.data || {};
        toast.success("OTP resend successful!");
        setTimer(120); // Reset the timer
        handleFormStateData(data?.token);
      }
    } catch (error) {
      console.error("Something went wrong, Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  useEffect(() => {
    if (timer <= 0) {
      setIsResendDisabled(false);
      return;
    }

    const interval = setInterval(() => {
      setTimer((prev) => prev - 1);
    }, 1000);

    setIsResendDisabled(true);

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    setAllFieldsFilled(otp.every((digit) => digit !== ""));
  }, [otp]);

  return (
    <>
      <form onSubmit={handleOtpVerification}>
        <h2 className="text-2xl font-bold text-center">Verify OTP</h2>
        <div className="flex flex-col space-y-4">
          <p className="text-sm font-medium bg-green-200 text-green-600 border rounded-md p-2 flex flex-wrap items-start">
            We have sent an OTP sent to: {email}
          </p>
          <p className="block text-sm font-medium text-gray-700">Enter OTP</p>
          <div className="flex justify-between space-x-1 sm:space-x-2">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-${index}`}
                type="number"
                maxLength={1}
                className="w-1/6 flex-1 h-14 text-center border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                onPaste={(e) => handleOtpPaste(e, index)}
              />
            ))}
          </div>
          <div className="flex justify-between">
            <p>{formatTime(timer)}</p>
            <p
              onClick={() => {
                if (isResendDisabled) {
                  return;
                }
                handleResendOtp();
              }}
              className={`${
                isResendDisabled
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:underline cursor-pointer"
              }`}
            >
              Resend OTP
            </p>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-full"
            disabled={!allFieldsFilled}
            onClick={handleOtpVerification}
          >
            Verify OTP
          </button>
        </div>
      </form>
    </>
  );
};

export default OtpInput;
