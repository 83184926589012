import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { ChatHistory } from "../shared";
import { apiGet } from "../../api/apiService";

import { AddPenIcon, DocumentIcon, MenuICon, PlusIcon } from "../../assets";

interface HistoryItem {
  _id: string;
  title: string;
}
interface DocumentDetail {
  _id: string;
  prompt: string;
}

const ProtectedSidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [historyData, setHistoryData] = useState<HistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const determineTabFromLocation = () => {
    if (location.pathname.startsWith("/document")) {
      return "document";
    } else if (location.pathname.startsWith("/chat")) {
      return "chat";
    }
    return "chat"; // Default tab
  };

  const activeTab = determineTabFromLocation();

  const fetchHistoryData = async (type: "chat" | "document") => {
    const endpoint = type === "chat" ? "/chat/history" : "/document/get";
    try {
      setLoading(true);
      const response = await apiGet(endpoint);
      if (response.status === 200) {
        let data: HistoryItem[] = [];
        if (type === "chat") {
          data = response?.data?.data as HistoryItem[];
        } else {
          data =
            response?.data?.data?.documents.map((doc: DocumentDetail) => ({
              _id: doc._id,
              title: doc.prompt,
            })) ?? [];
        }
        setHistoryData(data);
      } else {
        setHistoryData([]);
      }
    } catch (error) {
      console.error(`Error fetching ${type} history:`, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch history based on the current active tab derived from the location
    fetchHistoryData(activeTab);
  }, [activeTab]);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const handleToggleSidebar = () => setIsOpen(!isOpen);

  return (
    <>
      <aside
        className={`${
          isOpen
            ? "flex flex-col h-screen bg-[#171717] text-white w-64 transition-width duration-300 z-20 px-2 max-md:absolute"
            : "w-10 max-md:w-0 max-md:z-50"
        }`}
        aria-label="Sidebar"
      >
        <div className="flex items-center justify-between p-4">
          <button
            className="focus:outline-none"
            onClick={handleToggleSidebar}
            aria-label="Toggle sidebar"
          >
            <MenuICon className="h-6 w-6" />
          </button>
          {isOpen && (
            <button
              className="focus:outline-none"
              aria-label={`Add new ${activeTab}`}
              onClick={() => handleNavigate(`/${activeTab}`)}
            >
              {activeTab === "chat" ? (
                <AddPenIcon className="h-6 w-6" />
              ) : (
                <DocumentIcon className="h-6 w-6" />
              )}
            </button>
          )}
        </div>

        {isOpen && (
          <>
            <div className="mt-5">
              <ul className="space-y-2">
                <li
                  className="px-4 py-2 transition duration-300 ease-in-out hover:bg-opacity-30 hover:bg-[#30364296] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg cursor-pointer"
                  onClick={() => handleNavigate("/chat")}
                >
                  <p className="flex items-center font-bold">
                    <PlusIcon className="inline-block mr-2 h-5 w-5" />
                    Chat
                  </p>
                </li>
                <li
                  className="px-4 py-2 transition duration-300 ease-in-out hover:bg-opacity-30 hover:bg-[#30364296] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg cursor-pointer"
                  onClick={() => handleNavigate("/document")}
                >
                  <p className="flex items-center font-bold">
                    <DocumentIcon className="inline-block mr-2 h-5 w-5" />
                    Documents
                  </p>
                </li>
              </ul>
            </div>
            <ChatHistory
              data={historyData}
              type={activeTab}
              loading={loading}
            />
          </>
        )}
      </aside>
    </>
  );
};

export default ProtectedSidebar;
