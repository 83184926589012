import { lazy } from "react";

/**
 * Common pages
 */
const Home = lazy(() => import("./home/Home"));
const Faq = lazy(() => import("./publicPages/Faq"));
const TermsConditions = lazy(() => import("./publicPages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("./publicPages/PrivacyPolicy"));
const HelpAndSupport = lazy(() => import("./publicPages/HelpAndSupport"));
const Contact = lazy(() => import("./publicPages/Contact"));
const Features = lazy(() => import("./publicPages/Features"));
const About = lazy(() => import("./publicPages/About"));
const BlogList = lazy(() => import("./blog/BlogList"));
const BlogDetails = lazy(() => import("./blog/BlogDetails"));

/**
 * Auth Pages
 */
const Login = lazy(() => import("./auth/Login"));
const Signup = lazy(() => import("./auth/Signup"));
const ForgotPassword = lazy(() => import("./auth/ForgotPassword"));

/**
 * Private Pages
 */
const ChatApp = lazy(() => import("./chat/Chat"));
const ChatDetail = lazy(() => import("./chat/ChatDetail"));
const GenreateDocument = lazy(() => import("./document/Document"));
const DocumentDetails = lazy(() => import("./document/DocumentDetails"));

export {
  Home,
  Faq,
  TermsConditions,
  PrivacyPolicy,
  HelpAndSupport,
  Contact,
  Features,
  About,
  Login,
  Signup,
  ForgotPassword,
  ChatApp,
  ChatDetail,
  BlogList,
  BlogDetails,
  GenreateDocument,
  DocumentDetails,
};
