import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProfileModal from "../modals/ProfileModal";
import UserProfileModal from "../modals/UserProfileModal";

import { Avatar1 } from "../../assets";

const ProtectedHeader: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const avatarRef = useRef<HTMLDivElement>(null);
  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleOpenProfileModal = () => {
    setIsProfileOpen(true);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <>
      <header className="relative flex items-center justify-between p-2 max-md:justify-end max-md:gap-24">
        <p
          className="text-3xl font-bold text-white cursor-pointer"
          onClick={() => {
            handleNavigate("/");
          }}
        >
          Justice<span className="text-blue-600">AI</span>
        </p>
        <img
          src={Avatar1}
          alt="Profile Img"
          className="h-10 rounded-full cursor-pointer"
          onClick={toggleModal}
        />
      </header>
      <ProfileModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        anchorRef={avatarRef}
        handleOpenProfileModal={handleOpenProfileModal}
      />
      <UserProfileModal
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
      />
    </>
  );
};

export default ProtectedHeader;
