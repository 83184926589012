import { configureStore } from "@reduxjs/toolkit";
import authSlice, { logout, selectTokenValid } from "./reducer/auth/authSlice";

const expiredTokenMiddleware = () => (next: any) => (action: any) => {
  const state = store.getState();
  const tokenValid = selectTokenValid(state);

  if (!tokenValid && action.type !== logout.type) {
    store.dispatch(logout());
  }

  return next(action);
};

const store = configureStore({
  reducer: {
    auth: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(expiredTokenMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
