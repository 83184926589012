import React from "react";

const ChatHistoryLoader: React.FC = () => {
  return (
    <ul className="space-y-2 mt-3">
      {/* Simulating a list of chat history items with user and assistant color scheme */}
      {[1, 2, 3, 4].map((index) => (
        <li
          key={index}
          className="relative px-5 py-2 transition duration-300 ease-in-out hover:bg-opacity-30 hover:bg-[#30364296] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg cursor-pointer"
        >
          {/* Simulated chat title (User color: blue) */}
          <div className="h-4 bg-blue-500 rounded w-3/4 mb-2 animate-pulse"></div>
          {/* Simulated chat preview text (Assistant color: gray) */}
          <div className="h-4 bg-gray-700 rounded w-1/2 animate-pulse"></div>
          <p className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none text-gray-400">
            <div className="w-[20px] h-[20px] bg-gray-600 rounded-full animate-pulse"></div>
          </p>
        </li>
      ))}
    </ul>
  );
};

export default ChatHistoryLoader;
