import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../../redux/store";

import { ArrowRightIcon, MenuIcon } from "../../assets";

const Header: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    navigate(url);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="sticky top-0 backdrop-blur-sm z-20">
        {/* header top section */}
        <div className="flex justify-center items-center py-3 bg-black text-white text-sm gap-3">
          <p className="text-white/60 hidden md:block">
            Innovating Justice with AI-Driven Solutions
          </p>
          <div className="inline-flex gap-1 items-center">
            <p
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault(); // Prevent the default link behavior
                const element = document.getElementById("justice-ai-app");
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }}
            >
              Discover Justice AI Today
            </p>
            <ArrowRightIcon className="h-4 w-4 inline-flex justify-center items-center" />
          </div>
        </div>
        {/* header section */}
        <div className="py-2">
          <div className="container">
            <div className="flex items-center justify-between">
              {/* <img src={Logo} alt="Logo" height={40} width={40} /> */}
              <div className="inline-flex items-center justify-center relative">
                <Link to="/" className="text-3xl font-bold text-black">
                  Justice<span className="text-blue-600">AI</span>
                </Link>
              </div>
              <MenuIcon className="h-5 w-5 md:hidden" onClick={toggleMenu} />
              <nav className="hidden md:flex gap-6 text-black/60 items-center">
                <NavLink
                  to="/about-us"
                  closeMenu={closeMenu}
                  location={location}
                >
                  About
                </NavLink>
                <NavLink to="/blog" closeMenu={closeMenu} location={location}>
                  Blog
                </NavLink>
                <NavLink
                  to="/features"
                  closeMenu={closeMenu}
                  location={location}
                >
                  Features
                </NavLink>
                <NavLink
                  to="/contact-us"
                  closeMenu={closeMenu}
                  location={location}
                >
                  Contact
                </NavLink>
                {isAuthenticated ? (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleNavigation("/document");
                      }}
                    >
                      Dashboard
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        handleNavigation("/user/sign_in");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        handleNavigation("/user/sign_up");
                      }}
                    >
                      New Account
                    </button>
                  </>
                )}
              </nav>
            </div>
          </div>
        </div>
      </header>
      {/* Mobile Menu Modal */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-30 bg-black bg-opacity-70 backdrop-blur-sm flex justify-center items-center md:hidden"
          onClick={closeMenu}
        >
          <div
            className="bg-white p-8 rounded-lg w-3/4 max-w-sm text-center space-y-6 flex flex-col"
            onClick={(e) => e.stopPropagation()}
          >
            <NavLink to="/about-us" closeMenu={closeMenu} location={location}>
              About
            </NavLink>
            <NavLink to="/blog" closeMenu={closeMenu} location={location}>
              Blog
            </NavLink>
            <NavLink to="/features" closeMenu={closeMenu} location={location}>
              Features
            </NavLink>
            <NavLink to="/contact-us" closeMenu={closeMenu} location={location}>
              Contact
            </NavLink>
            {isAuthenticated ? (
              <>
                <button
                  className="btn btn-primary w-full"
                  onClick={() => {
                    handleNavigation("/document");
                  }}
                >
                  Dashboard
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-secondary w-full"
                  onClick={() => {
                    handleNavigation("/user/sign_in");
                  }}
                >
                  Get Started
                </button>
                <button
                  className="btn btn-primary w-full"
                  onClick={() => {
                    handleNavigation("/user/sign_up");
                  }}
                >
                  New Account
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

// NavLink Component with TypeScript
interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  closeMenu: () => void;
  location: ReturnType<typeof useLocation>;
}

const NavLink: React.FC<NavLinkProps> = ({
  to,
  children,
  closeMenu,
  location,
}) => {
  const isActive = location.pathname === to;
  return (
    <Link
      to={to}
      className={`link ${isActive ? "active-link" : ""}`}
      onClick={closeMenu}
    >
      {children}
    </Link>
  );
};
