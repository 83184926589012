import React, { useRef, useEffect } from "react";
import { SendIcon } from "../../assets";

interface SearchInputProps {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  onSend: () => void;
  loading: boolean;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = "Message JusticeAI",
  onChange,
  value,
  onSend,
  loading,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      // Reset height to auto to calculate the correct scrollHeight
      textareaRef.current.style.height = "auto";
      // Set the height based on the scrollHeight
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <div className="fixed bottom-0 w-full max-w-2xl py-2 max-md:max-w-[80%]">
      <div className="relative w-full">
        <textarea
          ref={textareaRef}
          className="w-full p-4 bg-[#2f2f2f] border-none rounded-3xl shadow-sm focus:outline-none pr-14 resize-none"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyDown={handleKeyDown}
          rows={1} // Default to one row height
          style={{ overflowY: "hidden" }} // Prevent scrollbar
        />
        <div className="absolute inset-y-0 right-0 flex items-center space-x-2 pr-4 bg-transparent">
          <button onClick={onSend} aria-label="Send Message">
            <SendIcon
              className={`h-6 w-6 ${(!value || loading) && "opacity-15"}`}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
