import React from "react";

interface FeatureCardProps {
  title: string;
  description: string;
  gradient: string;
  onSelect: () => void;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  description,
  gradient,
  onSelect,
}) => {
  return (
    <div
      onClick={onSelect}
      className={`cursor-pointer p-6 bg-gradient-to-r ${gradient} shadow-lg rounded-xl transform hover:scale-105 transition-transform duration-300`}
    >
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default FeatureCard;
