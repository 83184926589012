import React from "react";

const Loader: React.FC = () => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-screen bg-[#000000d4] flex justify-center items-center z-50">
        <p className="text-3xl font-bold text-white">
          Justice<span className="text-blue-600">AI</span>
        </p>
      </div>
    </>
  );
};

export default Loader;
