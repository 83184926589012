import React from "react";
import { Link } from "react-router-dom";

import {
  SocialInstaIcon,
  SocialLinkedinIcon,
  SocialXIcon,
  SocialYoutubeIcon,
} from "../../assets";

const Footer: React.FC = () => {
  return (
    <>
      <footer className="bg-black text-[#BCBCBC] text-sm py-10 text-center">
        <div className="container">
          {/* <div className="inline-flex relative before:content-[''] before:top-2 before:bottom-0 before:blur before:w-full before:bg-[linear-gradient(to_right,#f878FF,#fB92CF,#FFDD98,#C2F0B1,#2FD8FE)] before:absolute">
            <img
              src={Logo}
              alt="logo"
              width={40}
              height={40}
              className="relative"
            />
          </div> */}
          <div className="inline-flex items-center justify-center relative">
            <p className="text-3xl font-bold text-white">
              Justice<span className="text-blue-600">AI</span>
            </p>
          </div>
          <nav className="flex flex-col md:flex-row md:justify-center gap-6 mt-6">
            <Link to="/about-us" className="hover-link">
              About
            </Link>
            <Link to="/blog" className="hover-link">
              Blog
            </Link>
            <Link to="/features" className="hover-link">
              Features
            </Link>
            <Link to="/terms-and-conditions" className="hover-link">
              Terms and condition
            </Link>
            <Link to="/privacy-policy" className="hover-link">
              Privacy policy
            </Link>
            <Link to="/faq" className="hover-link">
              FAQ
            </Link>
            <Link to="/contact-us" className="hover-link">
              Contact Us
            </Link>
          </nav>
          <div className="flex justify-center gap-6 mt-6">
            <SocialXIcon className="hover-icon" />
            <a
              href="https://www.instagram.com/justiceai.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialInstaIcon className="hover-icon" />
            </a>
            <SocialYoutubeIcon className="hover-icon" />
            <SocialLinkedinIcon className="hover-icon" />
          </div>
          <p className="text-[#BCBCBC] text-sm mt-6 transition duration-300 ease-in-out">
            &copy; 2024 <span className="font-bold">Justice AI</span>, Inc. All
            rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
