import React from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import store from "./redux/store";
import AppRoutes from "./routes/AppRoutes";
// import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <>
      {/* <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
      > */}
      <Provider store={store}>
        <AppRoutes />
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
      {/* </GoogleOAuthProvider> */}
    </>
  );
};

export default App;
