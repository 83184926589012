import React from "react";
import { Outlet } from "react-router-dom";

import ProtectedHeader from "../sections/ProtectedHeader";
import ProtectedSidebar from "../sections/ProtectedSidebar";

const ProtectedLayout: React.FC = () => {
  return (
    <>
      <section className="flex h-screen bg-[#212121] text-white animate-fade-in overflow-hidden">
        <ProtectedSidebar />
        <div className="flex flex-col flex-grow h-full">
          <ProtectedHeader />
          <main className="flex-grow p-6 relative h-screen overflow-y-auto scrollbar-hide">
            <Outlet />
          </main>
        </div>
      </section>
    </>
  );
};

export default ProtectedLayout;
