const validateEmail = (value: string): string => {
  if (!value) {
    return "Email is required";
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    return "Please enter a valid email address";
  }
  return "";
};

const validatePhoneNumber = (value: string): string => {
  if (!value || value.length < 10 || value.length > 10) {
    return "Please enter a valid phone number";
  }
  return "";
};

const validatePassword = (value: string): string => {
  // Assume password must be at least 8 characters and include at least one symbol, one uppercase, and one lowercase letter
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;
  if (!value) {
    return "Password is required";
  } else if (!passwordRegex.test(value)) {
    return "Password must be 8 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.";
  }
  return "";
};

const validateUsername = (value: string): string => {
  if (!value) {
    return "Username is required";
  } else if (value.length < 3) {
    return "Username must be at least 3 characters long";
  }
  return "";
};
// Function to check if name has more than 3 words
const validateName = (name: string): string => {
  const wordCount = name?.length;
  return wordCount < 3 ? "Name must be more than 3 words" : "";
};

// Function to check if subject contains potential SQL injection patterns
const validateSubject = (subject: string): string => {
  const injectionPattern = /['"=;\\-]/; // Check for common SQL injection characters
  return injectionPattern.test(subject) ? "Invalid characters in subject" : "";
};

// Function to validate message length
const validateMessageLength = (message: string): string => {
  const minLength = 10;
  const maxLength = 500;
  if (message.length < minLength) {
    return `Message must be at least ${minLength} characters long`;
  }
  if (message.length > maxLength) {
    return `Message cannot be longer than ${maxLength} characters`;
  }
  return "";
};

const TOKEN_KEY = "token";
const EMAIL_KEY = "email";
const TOKEN_EXPIRATION_KEY = "tokenExpiration";
const ROLES_KEY = "role";
const USER_NAME = "userName";
const LOCAL_STORAGE_KEYS = {
  activeTab: "activeTab", // Key for storing the active tab in localStorage
};

// Function to format message with line breaks and bold text
const formatMessage = (content: string) => {
  // Replace line breaks with <br />
  let formattedContent = content.replace(/\n/g, "<br />");

  // Replace markdown-style **bold** with <strong>
  formattedContent = formattedContent.replace(
    /\*\*(.*?)\*\*/g,
    "<strong>$1</strong>"
  );

  return formattedContent;
};

export {
  validateEmail,
  validatePhoneNumber,
  validatePassword,
  validateUsername,
  validateMessageLength,
  validateName,
  validateSubject,
  TOKEN_KEY,
  EMAIL_KEY,
  TOKEN_EXPIRATION_KEY,
  ROLES_KEY,
  USER_NAME,
  formatMessage,
  LOCAL_STORAGE_KEYS,
};
