import React, { useState } from "react";
import {
  CrossIcon,
  ProfileUserIcon,
  SecurityIcon,
  SettingIcon,
} from "../../assets";

interface UserProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TabData {
  id: string;
  name: string;
  icon: React.ReactNode;
}

const tabs: TabData[] = [
  { id: "profile", name: "Profile", icon: <ProfileUserIcon /> },
  { id: "general", name: "General", icon: <SettingIcon /> },
  { id: "security", name: "Security", icon: <SecurityIcon /> },
];

const UserProfileModal: React.FC<UserProfileModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-[#2f2f2f] w-full max-w-3xl rounded-lg shadow-lg">
        <div className="border-b border-gray-200">
          <div className="flex justify-between items-center p-6">
            <h2 className="text-xl font-semibold">Setting</h2>
            <button onClick={onClose} aria-label="Close modal">
              <CrossIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="flex gap-5 p-6">
          {/* Subtab Section */}
          <div className="">
            <ul className="space-y-4">
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  className={`cursor-pointer p-2 w-auto ${
                    activeTab === tab.id ? "bg-[#4b4b4b] rounded-lg" : ""
                  } hover:bg-[#4b4b4b] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg`}
                  onClick={() => setActiveTab(tab.id)}
                >
                  <div className="flex items-center gap-3">
                    {tab.icon}
                    {tab.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* Content Section */}
          <div className="">
            <h3 className="text-lg font-semibold mb-2">
              {tabs.find((t) => t.id === activeTab)?.name} Details
            </h3>
            <p>Coming Soon...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileModal;
