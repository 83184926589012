import React from "react";

// import { jwtDecode } from "jwt-decode";

import { GoogleImage } from "../../assets";
// import { GoogleLogin } from "@react-oauth/google";
// import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";

const OAuth: React.FC = () => {
  //   const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const handleGoogleClick = async () => {
    console.log("object");
  };

  return (
    <>
      {/* {!isAuthenticated && (
        <GoogleLogin
          onSuccess={(credentialResponse: any) => {
            const details = jwtDecode(credentialResponse.credential);
            const userData = details;
            console.log(userData);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      )} */}
      <button
        className="w-full px-4 py-2 font-medium text-gray-700 bg-white border rounded-md hover:bg-gray-50"
        onClick={handleGoogleClick}
      >
        <img src={GoogleImage} alt="Google" className="inline-block w-5 mr-2" />
        Continue with Google
      </button>
    </>
  );
};

export default OAuth;
