import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams

import ChatOptionsModal from "./ChatOptionsModal";
import ChatHistoryLoader from "../loader/ChatHistoryLoader";

import { HistoryIcon, DotMenuIcon } from "../../assets";

interface HistoryItem {
  _id: string;
  title: string;
}

interface ChatHistoryProps {
  data: HistoryItem[]; // Data for either chat or document history
  type: "chat" | "document"; // Type to differentiate between chat and document
  loading: boolean; // State to indicate if the data is loading
}

const ChatHistory: React.FC<ChatHistoryProps> = ({ data, type, loading }) => {
  const navigate = useNavigate();
  const { chatId, documentId } = useParams<{
    chatId?: string;
    documentId?: string;
  }>(); // Get params based on URL
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleSetActiveItem = (id: string) => {
    const path = type === "chat" ? `/chat/${id}` : `/document/${id}`;
    navigate(path);
  };

  const handleOpenOptions = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation(); // Prevent triggering parent click events
    setSelectedItemId(id);
    setIsOptionsOpen(true);
  };

  const handleCloseOptions = () => {
    setIsOptionsOpen(false);
    setSelectedItemId(null);
  };

  const isActive = (itemId: string) => {
    return type === "chat" ? itemId === chatId : itemId === documentId;
  };

  return (
    <>
      <section className="mt-8 overflow-y-auto">
        <h2 className="text-lg font-semibold pl-4 flex items-center">
          <HistoryIcon className="mr-2" />
          {type === "chat" ? "Chat History" : "Document History"}
        </h2>
        {loading ? (
          <ChatHistoryLoader />
        ) : (
          <ul className="space-y-2 mt-3">
            {data.map((item) => (
              <li
                key={item._id}
                className={`relative px-5 py-2 transition duration-300 ease-in-out rounded-lg cursor-pointer ${
                  isActive(item._id)
                    ? "bg-[#303642c7] backdrop-filter backdrop-blur-lg shadow-lg"
                    : "hover:bg-opacity-30 hover:bg-[#303642c7] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg"
                }`}
                onClick={() => handleSetActiveItem(item._id)}
              >
                <span className="block truncate pr-8">{item.title}</span>
                {/* <p
                  onClick={(e) => handleOpenOptions(e, item._id)}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none text-gray-400 hover:text-white"
                  aria-label="More options"
                >
                  <DotMenuIcon className="w-[12px]" />
                </p> */}
              </li>
            ))}
          </ul>
        )}
      </section>
      <ChatOptionsModal
        isOpen={isOptionsOpen}
        onClose={handleCloseOptions}
        chatId={selectedItemId}
      />
    </>
  );
};

export default ChatHistory;
