import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useOutsideClick from "../../hooks/useOutsideClick";
import { logout } from "../../redux/reducer/auth/authSlice";

import { LogoutIcon, ProfileUserIcon, SettingIcon } from "../../assets";

interface ProfileModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  anchorRef: React.RefObject<HTMLDivElement>; // Reference to the anchor element (image)
  handleOpenProfileModal: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({
  isOpen,
  toggleModal,
  anchorRef,
  handleOpenProfileModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useOutsideClick(() => {
    if (isOpen) toggleModal();
  });

  const style = anchorRef.current
    ? anchorRef.current.getBoundingClientRect()
    : { top: 0, height: 0, right: 0 };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed bg-[#2f2f2f] rounded-lg shadow-lg flex justify-end items-start z-50"
      style={{ top: style.top + style.height + 50, right: 20 }} // Adding space at the bottom and aligning to the right
    >
      <div className="p-4 w-[200px] h-auto" ref={modalRef}>
        <ul>
          <li
            className="flex items-center gap-3 p-2 cursor-pointer hover:bg-[#4b4b4b] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg"
            onClick={handleOpenProfileModal}
          >
            <ProfileUserIcon className="w-4 h-4" />
            Profile
          </li>
          <li
            className="flex items-center gap-3 p-2 cursor-pointer hover:bg-[#4b4b4b] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg"
            onClick={handleOpenProfileModal}
          >
            <SettingIcon />
            Setting
          </li>
          <li
            className="flex items-center gap-3 p-2 cursor-pointer hover:bg-[#4b4b4b] hover:backdrop-filter hover:backdrop-blur-lg hover:shadow-lg rounded-lg"
            onClick={handleLogout}
          >
            <LogoutIcon />
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ProfileModal;
