import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../redux/store";
import Layout from "../components/layout/Layout";
import Loader from "../components/loader/Loader";
import ProctedLayout from "../components/layout/ProtectedLayout";
import {
  About,
  BlogDetails,
  BlogList,
  ChatApp,
  ChatDetail,
  Contact,
  DocumentDetails,
  Faq,
  Features,
  ForgotPassword,
  GenreateDocument,
  HelpAndSupport,
  Home,
  Login,
  PrivacyPolicy,
  Signup,
  TermsConditions,
} from "../pages";

const AppRoutes = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  // Route configurations
  const routeConfig = {
    publicRoutes: [
      { path: "/", element: <Home /> },
      { path: "/faq", element: <Faq /> },
      { path: "/terms-and-conditions", element: <TermsConditions /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/help-and-support", element: <HelpAndSupport /> },
      { path: "/contact-us", element: <Contact /> },
      { path: "/features", element: <Features /> },
      { path: "/blog", element: <BlogList /> },
      { path: "/blog/:id", element: <BlogDetails /> },
      { path: "/about-us", element: <About /> },
    ],
    authRoutes: [
      { path: "/user/sign_in", element: <Login /> },
      { path: "/user/sign_up", element: <Signup /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
    ],
    privateRoutes: [
      { path: "/document", element: <GenreateDocument /> },
      { path: "/document/:documentId", element: <DocumentDetails /> },
      { path: "/chat", element: <ChatApp /> },
      { path: "/chat/:chatId", element: <ChatDetail /> },
    ],
  };

  // Helper function to render routes
  const renderRoutes = (routes: { path: string; element: JSX.Element }[]) => {
    return routes.map(({ path, element }) => (
      <Route key={path} path={path} element={element} />
    ));
  };

  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Public Routes */}
          <Route element={<Layout />}>
            {renderRoutes(routeConfig.publicRoutes)}
          </Route>

          {/* Auth Routes */}
          {!isAuthenticated && renderRoutes(routeConfig.authRoutes)}

          {/* Private Routes */}
          {isAuthenticated && (
            <Route element={<ProctedLayout />}>
              {renderRoutes(routeConfig.privateRoutes)}
            </Route>
          )}

          <Route
            path="*"
            element={
              <Navigate to={isAuthenticated ? "/document" : "/"} replace />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
