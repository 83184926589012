import { toast } from "react-toastify";
import axiosInstance from "./axiosConfig";

const handleApiError = (error: any) => {
  if (error.response) {
    // Request made and server responded with a status code
    console.error("Response error:", error.response.data.error.msg);
    toast.error(
      `Error: ${error.response.data?.error?.msg || "Something went wrong"}`
    );
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response from server:", error.request);
    toast.error("No response from server. Please check your connection.");
  } else {
    // Something happened in setting up the request that triggered an error
    console.error("Request setup error:", error.message);
    toast.error("An error occurred. Please try again later.");
  }
};

// API GET request
export const apiGet = async (url: string) => {
  try {
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API POST request
export const apiPost = async (url: string, payload: any) => {
  try {
    const response = await axiosInstance.post(url, payload);
    return response;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API PUT request
export const apiPut = async (url: string, payload: any) => {
  try {
    const response = await axiosInstance.put(url, payload);
    return response;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// API DELETE request
export const apiDelete = async (url: string) => {
  try {
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};
