import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthResponse, AuthState } from "../types/authTypes";
import {
  ROLES_KEY,
  TOKEN_EXPIRATION_KEY,
  TOKEN_KEY,
  USER_NAME,
} from "../../../utils";

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem(TOKEN_KEY),
  token: localStorage.getItem(TOKEN_KEY) ?? null,
  username: localStorage.getItem(USER_NAME) ?? null,
  role: localStorage.getItem(ROLES_KEY) ?? null,
  tokenExpiration: localStorage.getItem(TOKEN_EXPIRATION_KEY)
    ? parseInt(localStorage.getItem(TOKEN_EXPIRATION_KEY)!)
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<AuthResponse>) {
      const { token, username, role } = action.payload;
      state.isAuthenticated = true;
      state.token = token;
      state.username = username;
      state.role = role;
      state.tokenExpiration = Date.now() + 7 * 24 * 60 * 60 * 1000;
      localStorage.setItem(TOKEN_KEY, token);
      localStorage.setItem(USER_NAME, username);
      localStorage.setItem(ROLES_KEY, role);
      localStorage.setItem(
        TOKEN_EXPIRATION_KEY,
        state.tokenExpiration.toString()
      );
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.username = null;
      state.role = null;
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem(USER_NAME);
      localStorage.removeItem(ROLES_KEY);
      localStorage.removeItem(TOKEN_EXPIRATION_KEY);
    },
  },
});

// Function to check if the token is expired
export const selectTokenValid = (state: { auth: AuthState }) => {
  const { token, tokenExpiration } = state.auth;
  return token && tokenExpiration && tokenExpiration > Date.now();
};

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
